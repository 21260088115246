!(function (s) {
  function n(t, n, e, i) {
    var r = t.text().split(n),
      c = "";
    r.length &&
      (s(r).each(function (t, n) {
        c += '<span class="' + e + (t + 1) + '">' + n + "</span>" + i;
      }),
      t.empty().append(c));
  }
  var e = {
    init: function () {
      return this.each(function () {
        n(s(this), "", "char", "");
      });
    },
    words: function () {
      return this.each(function () {
        n(s(this), " ", "word", " ");
      });
    },
    lines: function () {
      return this.each(function () {
        var t = "eefec303079ad17405c889e092e105b0";
        n(s(this).children("br").replaceWith(t).end(), t, "line", "");
      });
    },
  };
  s.fn.lettering = function (t) {
    return t && e[t]
      ? e[t].apply(this, [].slice.call(arguments, 1))
      : "letters" !== t && t
      ? (s.error("Method " + t + " does not exist on jQuery.lettering"), this)
      : e.init.apply(this, [].slice.call(arguments, 0));
  };
})(jQuery);

